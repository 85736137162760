import React from 'react';
import {TASK_ACTIONS, CONFIRMATION} from 'utils/constants';
import PostponePopup from '../popup/PostponePopup.component';
import RejectPopup from '../popup/RejectPopup.component';
import SendForwardPopup from '../popup/SendForwardPopup.component';
import EmailPopup from '../popup/EmailPopup.component';
import ApprovePopup from '../popup/ApprovePopup.component';
import ReviewPopup from '../popup/TaskReviewedPopup.component';
import RequestReviewPopup from '../popup/RequestReviewPopup.component';
import AddAttachmentPopup from '../popup/AddAttachmentPopup.component';
import DeleteAttachmentPopup from '../popup/DeleteAttachmentPopup.component';
import ReApprovePopUp from '../popup/ReApprovePopup.component';

import {connect} from 'react-redux';
import {loadTasks, addTaskToBatch, seamlessLoadTasks} from './myTasks.action';
import { actionFinished } from "./action/taskAction.action";
import {handleTask} from './action/handleTask.action';
import {approveMultipleTasks} from './action/approveMultipleTasks.action';
import {
    getTasksForRender,
    getActionParameters,
    getUsersSettings,
    getDocumentEditedFlag,
    getUsersShowCommentOnApprove,
    getActiveBatch,
    getHandledTasksKeys,
    getUsersLoggedInData
} from '../store/application.reducers';
import {showNotification} from '../notifications/notification.action';
import * as logger from '../../utils/logger.function';
import {navigateBetweenTasks} from '../mytasks/action/navigateBetweenTasks.action';
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import { launchDarkly } from "utils/launchDarkly";
import { useModalContext } from "components/modals/ModalContext";

/**
 * asynchronous part of the task action - showing the dialog, which will execute the action
 * synchronous part of action is inside redux action
 */
export const AsyncTaskActionExecutor = (props) => {
    const navigate = useNavigate();
    const useNewModals = launchDarkly.companyClient && launchDarkly.companyClient.variation("use-new-modals", false);
    const modalContext = useModalContext();

    const {toggleModal} = modalContext || {};
    const closeDialogCallback = () => {
        props.actionFinished();
       // props.seamlessLoadTasks();
    }

    // nothing to show
    if (!props.actionParameters.type) {
        return null;
    }

    let taskList = props.tasks.allTasks.map((task) => task.task);

    const tasksForAction = taskList
        .filter((task) => { // find those matching props.taskIds
            return props.actionParameters.taskIds.some((taskKeyWanted) => {
                return taskKeyWanted === task.key;
            });
        });

    /**
     * sometimes, when user has dialog opened and navigates away for some time or changes context
     * the keys for task/history details expire, which then results in empty tasks for the popup
     * so detect this situation and rather hide the popup than check for invalid props inside each popup
     */
    if (tasksForAction.length === 0) {
        logger.debug("AsyncTaskActionExecutor", "no task to be shown for these keys: " + props.actionParameters.taskIds);
        props.actionFinished();
        return null;
    }

    let isCurrentTaskHandled = _.findIndex(props.handledTasks, (id) => {
        return id === tasksForAction[0].key
    }) !== -1;


    let closeCallback = closeDialogCallback.bind(this);

    const popupProps = {
        closeCallback: closeCallback,
        handledTaskCallback: props.handleTask,
        translate: props.translate,
        tasks: tasksForAction,
        showNotification: props.showNotification,
        refreshTaskList: props.seamlessLoadTasks,
        approveMultipleTasks: props.approveMultipleTasks,
        taskDetails: props.taskDetails,
        isCurrentTaskHandled: isCurrentTaskHandled,
        navigate: navigate,
    };

    let popup = null;
    // according to type either show popup or execute action directly ..
    switch (props.actionParameters.type) {
        case TASK_ACTIONS.APPROVE:
            if (props.taskDetails && props.docEditedFlag && props.taskDetails.reapproveType === CONFIRMATION && props.taskDetails.skipRestart === true) {
                popup = <ReApprovePopUp {...popupProps} userSettings={props.userSettings}/>
            } else {
                popup = <ApprovePopup {...popupProps} showComments={props.showCommentsArea}/>;
            }
            break;

        case TASK_ACTIONS.SEND_FOR_REVIEW:
            popup = <RequestReviewPopup {...popupProps} />;
            break;

        case TASK_ACTIONS.REVIEW:
            popup = <ReviewPopup {...popupProps} />;
            break;

        case TASK_ACTIONS.EMAIL:
            popup = <EmailPopup {...popupProps} loggedInData={props.loggedInData}/>;
            break;

        case TASK_ACTIONS.FORWARD:
            if (useNewModals) {
                toggleModal(TASK_ACTIONS.FORWARD, {tasks: tasksForAction, isCurrentTaskHandled: isCurrentTaskHandled, closeModal:closeCallback});
            } else {
                popup = <SendForwardPopup {...popupProps} />;
            }
            break;

        case TASK_ACTIONS.POSTPONE:
            if (useNewModals) {
                toggleModal(TASK_ACTIONS.POSTPONE, {tasks: tasksForAction, closeModal: closeCallback});
            }
            else {
                popup = <PostponePopup {...popupProps} />;
            }
            break;
        case TASK_ACTIONS.REJECT:
            popup = <RejectPopup {...popupProps} />;
            break;
        case TASK_ACTIONS.ADD_ATTACHMENT:
            if (useNewModals) {
                toggleModal(TASK_ACTIONS.ADD_ATTACHMENT, {tasks: tasksForAction, closeModal:closeCallback});
            } else {
                popup = <AddAttachmentPopup {...popupProps} />;
            }
            break;

        case TASK_ACTIONS.DELETE_ATTACHMENT:
            if (useNewModals) {
                toggleModal(TASK_ACTIONS.DELETE_ATTACHMENT, {tasks: tasksForAction, closeModal:closeCallback});
            } else {
                popup = <DeleteAttachmentPopup {...popupProps} />;
            }
            break;

        case TASK_ACTIONS.NEXT_TASK:
            const nextTaskId =props.navigateBetweenTasks(tasksForAction[0].key, true);
            navigate(nextTaskId, {replace:true});
            props.actionFinished();
            break;
        case TASK_ACTIONS.PREVIOUS_TASK:
            const previousTaskId = props.navigateBetweenTasks(tasksForAction[0].key, false);
            navigate(previousTaskId, {replace: true});
            props.actionFinished();
            break;

        case TASK_ACTIONS.APPROVE_BATCH:
            const batchID = props.activeBatch ? props.activeBatch.id : undefined;
            const version = props.activeBatch ? props.activeBatch.version : undefined;
            props.addTaskToBatch(batchID, version, tasksForAction[0].id, tasksForAction[0].key);
            props.actionFinished();
            break;

        default:
            console.error('unmapped action type: ' + props.actionParameters.type);

    }
    return popup;

}


const mapStateToProps = function (store) {
    return {
        tasks: getTasksForRender(store),
        actionParameters: getActionParameters(store),
        userSettings: getUsersSettings(store),
        docEditedFlag: getDocumentEditedFlag(store),
        showCommentsArea: getUsersShowCommentOnApprove(store),
        activeBatch: getActiveBatch(store),
        handledTasks: getHandledTasksKeys(store),
        loggedInData: getUsersLoggedInData(store)
    };
};

const connected = connect(mapStateToProps, {
    actionFinished,
    handleTask,
    showNotification,
    loadTasks,
    approveMultipleTasks,
    navigateBetweenTasks,
    addTaskToBatch,
    seamlessLoadTasks
})(AsyncTaskActionExecutor);


export default connected;