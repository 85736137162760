import React, { useEffect } from "react";
import translate from "components/translations/translations.wrapper.jsx";
import Postpone from "components/modals/Postpone.modal";

import Reject from "components/modals/Reject.modal";
import Forward from "components/modals/Forward.modal";
import { MODAL_ERROR, MODAL_SEVERITY, TASK_ACTIONS } from "utils/constants";
import DeleteAttachment from "components/modals/DeleteAttachment.modal";
import ErrorModal from "components/modals/Error.modal";
import AddAttachment from "components/modals/AddAttachment.modal";


const TAB_INDEX = 1000;
const TypeMap = {
    [TASK_ACTIONS.REJECT]: Reject,
    [TASK_ACTIONS.FORWARD]: Forward,
    [TASK_ACTIONS.DELETE_ATTACHMENT]: DeleteAttachment,
    [TASK_ACTIONS.ADD_ATTACHMENT]: AddAttachment,
    [MODAL_ERROR]: ErrorModal,
    [TASK_ACTIONS.POSTPONE]: Postpone,
};


export const Modal = ({
                          type,
                          more,
                          toggleModal,
                          translate,
                      }: any): React.ReactElement => {

    const closeButtonRef: React.LegacyRef<HTMLButtonElement> = React.createRef();
    let Component: React.ReactElement | (({ more }: any) => React.ReactElement) = TypeMap[type];
    let keyPressEventHandle = null;
    const modalRef = React.createRef<HTMLDialogElement>();
    const severity = more?.severity || MODAL_SEVERITY.INFO;

    const closeModal = () => {
        if (more?.closeModal) {
            more.closeModal();
        }
        toggleModal(null);
    };

    const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === "Escape") {
            closeModal();
        }
    };

    const loopFocus = () => {
        closeButtonRef.current?.focus();
    };

    const errorCallback = (error) => {
        closeModal();
        toggleModal(MODAL_ERROR, { ...error, severity: MODAL_SEVERITY.ERROR });
    };


    useEffect(() => {
        if (!keyPressEventHandle)
            keyPressEventHandle = modalRef.current?.addEventListener("keydown", handleKeyPress);

        //unmount
        return () => {
            modalRef.current?.removeEventListener("keydown", handleKeyPress);
        };
    });


    return (
        <div>
            {type &&
                <dialog className={"modal modal-" + severity + " clear ast_modal_" + type}
                        tabIndex={TAB_INDEX}
                        ref={modalRef}>
                    <div className={"pop-up-modal modal-dialog"} id={"dialogModal"}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type={"button"} className={"close :focus-visible"} data-toggle="modal"
                                        tabIndex={TAB_INDEX + 1}
                                        data-target="#dialogModal" aria-label="Close"
                                        ref={closeButtonRef}
                                        onClick={closeModal} />
                            </div>
                            <Component className={"position-absolute z-index-50"} translate={translate}
                                       loopFocus={loopFocus}
                                       closeModal={closeModal}
                                       errorCallback={errorCallback}
                                       more={more} />
                        </div>
                    </div>
                </dialog>
            }
        </div>
    );
};


const withTranslations = translate(Modal);
export default withTranslations;